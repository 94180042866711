import {Transaction} from './../types/transaction';
import {setDoc, getDocRef, getDoc, getCollectionRefDesc, getCollection, getCollectionRef} from '../libs/firestore';


function getTrades(projectId: string, modelId: string, limit = 20) {
  return getCollectionRef(`projects/${projectId}/models/${modelId}/PNL/trades/trades`);
}

function getTradesOnce(projectId: string, modelId: string, limit = 20) {
  return getCollection(`projects/${projectId}/models/${modelId}/PNL/trades/trades`);
}

function getTradeSubscription(projectId: string, modelId: string) {
  return getCollectionRef(`projects/${projectId}/models/${modelId}`);
}

function getTradeOnce(projectId: string, modelId: string, tradeId: string) {
  return getDoc(`projects/${projectId}/models/${modelId}/trades/${tradeId}`);
}

function updateTrade(projectId: string, modelId: string, id: string, trade: Transaction) {
  return setDoc(`projects/${projectId}/models/${modelId}/trades/${id}`, trade, true);
}

export {getTradeSubscription, getTradesOnce, getTrades, getTradeOnce, updateTrade}
