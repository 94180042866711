import {getDoc} from "@/libs/firestore";

function getLiveBlanklyMetrics(projectId: string, modelId: string) {
  return getDoc(`projects/${projectId}/models/${modelId}/metrics/blankly`)
}

function getLiveCustomMetrics(projectId: string, modelId: string) {
  return getDoc(`/projects/${projectId}/models/${modelId}/metrics/custom`)
}

function getLiveBlanklyTimeseriesMetrics(projectId: string, modelId: string) {
  return getDoc(`projects/${projectId}/models/${modelId}/timeseriesMetrics/blankly`)
}

export {getLiveBlanklyMetrics, getLiveBlanklyTimeseriesMetrics, getLiveCustomMetrics}
